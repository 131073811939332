import React from 'react';
import { Dropdown } from 'semantic-ui-react'
import './index.css'

const options = [
    {
        key: 'seconds',
        text: 'seconds',
        value: 'seconds',
    },
    {
        key: 'minutes',
        text: 'minutes',
        value: 'minutes',
    },
    {
        key: 'hours',
        text: 'hours',
        value: 'hours',
    },
    {
        key: 'days',
        text: 'days',
        value: 'days',
    },
]

const FrequencyDropdown = React.memo((props) => {
    return <Dropdown
        className='frequency-dropdown'
        placeholder='Frequency'
        selection
        options={options}
        defaultValue={options[1].value}
        defaultSelectedLabel={options[1]}
        onChange={(event, data) => props.onChange(data.value)}
    />
});

export default FrequencyDropdown
