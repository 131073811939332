import React, { useState } from 'react';
import isNumber from 'lodash/isNumber'
import toFinite from 'lodash/toFinite'
import { Input } from 'semantic-ui-react'
import './index.css'

const onChange = (value, setInputValue, prevInputValue) => {
    const extractedNum = toFinite(value);
    if (value === '') {
        setInputValue('');
    }
    else if (isNumber(extractedNum)) {
        setInputValue(extractedNum);
    } else {
        setInputValue(prevInputValue);
    }
};

const NumInput = React.memo((props) => {
    const [inputValue, setInputValue] = useState(30);
    props.onChange(inputValue);

    return <Input className="num-input" placeholder={props.placeholder} onChange={(event, data) => onChange(data.value, setInputValue, inputValue)} value={inputValue}/>
});

export default NumInput
