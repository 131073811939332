import React, { useState } from 'react';
import moment from 'moment';
import './index.css';
import NumInput from '../NumInput'
import FrequencyDropdown from '../FrequencyDropdown';
import TextInput from '../TextInput';
import Plus from '../../assets/plus';
import Minus from '../../assets/minus';

const synth = window.speechSynthesis;
const selectedVoice = synth.getVoices().find(voice => voice.name.toLowerCase() === 'karen') || synth.getVoices()[0];

const metricToMSMultiplier = {
    'seconds': 1000,
    'minutes': 1000 * 60,
    'hours': 1000 * 60 * 60,
    'days': 1000 * 60 * 60 * 24,
}

let mutexCounter = 0;

let currentInterval;
let nextInterval;
let nextMillis;
let millis;

const getMillis = (frequency, timeMetric) => {
    return frequency * metricToMSMultiplier[timeMetric];
}

const speak = (utterance, timeoutMutex) => {
    if (timeoutMutex === mutexCounter) {
        nextMillis = millis;
        synth.speak(utterance);
    }
}

const onSetup = (frequency, timeMetric, taskText, setNextText) => {
    const utterance = new SpeechSynthesisUtterance(taskText);
    utterance.voice = selectedVoice;
    if (mutexCounter > 696969) {
        mutexCounter = 0;
    }
    const timeoutMutex = ++mutexCounter;

    if (currentInterval) {
        window.clearInterval(currentInterval);
    }
    if (nextInterval) {
        window.clearInterval(nextInterval);
    }

    // Set Voice Interval
    millis = getMillis(frequency, timeMetric);
    currentInterval = window.setInterval(() => speak(utterance, timeoutMutex), millis);

    // Set Next Text Interval
    nextMillis = millis;
    setNextText(moment.duration(nextMillis / 1000, 'seconds').humanize());
    nextInterval = window.setInterval(() => {
        nextMillis -= 1000;
        setNextText(moment.duration(nextMillis / 1000, 'seconds').humanize());
    }, 1000);
};

const onMinusClick = (killRow) => {
    if (currentInterval) {
        window.clearInterval(currentInterval);
    }
    if (nextInterval) {
        window.clearInterval(nextInterval);
    }
    killRow();
}

const UserSelections = React.memo((props) => {
    const [frequency, setFrequency] = useState(30);
    const [timeMetric, setTimeMetric] = useState('minutes');
    const [taskText, setTaskText] = useState('do twenty push ups');
    const [nextText, setNextText] = useState('');

    return (
        <>
            <div id="user-selection-wrapper">
                <span className="my-text">Every</span>
                <NumInput placeholder="1" onChange={val => setFrequency(val)}/>
                <FrequencyDropdown onChange={val => setTimeMetric(val)}/>
                <span className="my-text">tell me</span>
                <TextInput placeholder="do a thing" onChange={val => setTaskText(val)}/>
                <button className="start-button" onClick={() => onSetup(frequency, timeMetric, taskText, setNextText)}>start</button>
                {!props.killRow && <button className="more-button" onClick={props.createNewRow}><Plus/></button>}
                {props.killRow && <button className="fewer-button" onClick={() => onMinusClick(props.killRow)}><Minus/></button>}
            </div>
            <div className="additional-text-wrapper">
                <span className="my-text">{nextText ? `Next: ${nextText}` : 'Not yet started'}</span>
            </div>
        </>
    );
});

export default UserSelections
