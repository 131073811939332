import React, { useState } from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import without from 'lodash/without'
import UserSelections from  './components/UserSelections'

const killRow = (userSelectionses, setUserSelectionses, key) => {
  setUserSelectionses(without(userSelectionses, key));
}

const createNewRow = (userSelectionses, setUserSelectionses) => {
  setUserSelectionses([...(userSelectionses || []), userSelectionses.length]);
}

function App() {
  const [userSelectionses, setUserSelectionses] = useState([]);
  const renderedUserSelectionses = userSelectionses.map(i => <UserSelections key={i} killRow={() => killRow(userSelectionses, setUserSelectionses, i)}/>);

  return (
    <div id="wrapper">
      <h1 id="cronspeak-text">cronspeak<span id="slashies">//</span></h1>
      <UserSelections createNewRow={() => createNewRow(userSelectionses, setUserSelectionses)}/>
      {renderedUserSelectionses}
    </div>
  );
}

export default App;
