import React, { useState } from 'react';
import { Input } from 'semantic-ui-react'
import './index.css'

const onChange = (value, setInputValue) => {
    setInputValue(value);
};

const TextInput = React.memo((props) => {
    const [inputValue, setInputValue] = useState('do twenty push ups');
    props.onChange(inputValue);

    return <Input className="text-input input-sizer" placeholder={props.placeholder} onChange={(event, data) => onChange(data.value, setInputValue)} value={inputValue} />
});

export default TextInput
